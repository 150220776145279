var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn-toggle',{attrs:{"group":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tile":"","small":"","icon":"","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('config')}}},on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}])},[_c('span',[_vm._v("Configure")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tile":"","small":"","icon":"","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('clone')}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tile":"","small":"","icon":"","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('clear')}}},on),[_c('v-icon',[_vm._v("mdi-backspace")])],1)]}}])},[_c('span',[_vm._v("Clear assignments")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tile":"","small":"","icon":"","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('remove')}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Delete row")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }