













import Vue from 'vue'
import Planner from './components/Planner.vue'
import Cooldowns from './components/Cooldowns.vue'

import {loadFromURL} from './store/plugins/url'

export default Vue.extend({
  name: 'App',

  components: {
    Planner,
    Cooldowns,
  },
  
  watch: {
    '$route':{
      handler(to) {
        loadFromURL(this.$store, to)
      },
      immediate: true,
    },
  },
})
