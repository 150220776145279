

























import { assignProps, player, spell } from '../store/utils'
import WowIcon from './WowIcon.vue'
import Vue from 'vue'
import { SpellOptions } from './wow_info'

export default Vue.extend({
  components: {
    WowIcon,
  },
  
  props: {
    assignId: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...assignProps(['name', 'className', 'specName', 'playerId']),
    ...player(),
    ...spell(),
  },

  methods: {
    getValue(opt: SpellOptions<any>): any {
      const cfg = this.spell.cfg
      return cfg?.[opt.prop] || opt.default
    },

    setOpt(opt: SpellOptions<any>, v: any) {
      Vue.set(this.spell.cfg, opt.prop, v)
    },
  },
})
